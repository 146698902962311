
/* Top right debug box */
#debug-helper {
    font-size: 12px;
    line-height: 1.2;
    z-index: 9999;

    #debug-toggler {
        text-align: right;
        cursor: pointer;
        background: rgba(0,0,0, 0.4);
        padding: 8px 14px 10px 10px;
        text-decoration: none;
        position: fixed; 
        bottom: 150px;
        right:0px;
        user-select: none;
        opacity: 0.3;
        border-radius: 5px; border-top-right-radius: 0;border-bottom-right-radius: 0;
        
    &:hover {opacity: 1;}
        &::before { content: '+'; margin-right: 3px; font-size: 15px; position: relative; top: 1px; }
    }

    #debug-container {
        position: absolute; 
        top: 0; left: 0;
        display: none;
        background: #fff;
        padding: 5vh 5vw;
        border-radius: 3rem; 
        min-width: 70vw;
        min-height: 70vh;
        margin: 15vh 15vw;
        text-align: center;
        color: #333;
        box-shadow: 0 0 75px 50px rgba(0, 0, 0, 0.4);
        font-size: 14px;
        line-height: 1.4;
        strong { 
            color: #66D37E;
        }
        > div { margin-top: 5px; }

        .view-tree { margin-top: 50px; margin-bottom: 20px;

            .node-info-empty { opacity: 0.4; }
        }

        .do-container > div { 
            display: none; 
            
            &.open {
                display: block;
            }

            p { width: 60%; display: inline-block; margin: 0 auto; }

            .debug-option-content { 
                > div { display: none; }
                > div.open { display: block; }
            }

            dl {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                dt, dd {
                    flex-basis: 50%;
                    padding: 2px 20px;
                }
                dt { text-align: right;}
                dd { text-align: left; }
            }
        }

        ul {
            
            padding-left: 0;
            &.debug-menu { font-size: 16px; margin-bottom: 50px;}
            li { list-style: none; display: inline-block; padding: 5px 10px;  font-size: 12px;
                 a { cursor: pointer;}
                a.selected {
                    font-weight: 600;
                    color: #66D37E;
                }
            }

        }

        input[type=checkbox], input[type=radio] { position: relative; top: 2px; }
    }

    &.show-debug {
        #debug-toggler {
            &::before { content: '-'; top:-1px; }
        }
        #debug-container {
            display: block;
        }
    }
}

#debug-wrapper {
    position: absolute; top: 50px; max-width: 1200px; left: 0;  z-index: 9999;
    
#debug-wrapper { display: none; }
    /* Small info items on each element */

    .debug-render-log { display: none; }

    .debug-item {
        > div > strong { font-size: 15px; }
        margin-bottom: 10px;
        position: relative;
         font-size: 13px;
         display: none;
        > i { display: block; top: -30px; left: -22px; z-index: 98; border-radius: 5px;  color: #aaa; opacity: 0.4; cursor: pointer; background: rgba(0, 0, 0, 0.7);  padding: 5px 10px; font-weight: 600; 
            &:hover, &:active, &:focus { opacity: 1; }
            > span { color: #fff; font-style: normal; }
        }
        > div { 
            display: none; padding: 12px 16px;
            background: #eee;
            border-radius: 7px;
            border: 1px solid #ddd;
            z-index: 99;
            top: 30px;
        }
        &.show-debug {
            > div { display: block; }
        }
        .debug-more-toggler {  font-size: 12px; cursor: pointer;}
        .debug-more {
            display: none;
            font-size: 12px;
        }
        .debug-more-toggler:active + .debug-more,
        .debug-more-toggler:focus + .debug-more {
            display: block;
        }

        .debug-view-result > i { display: block; }
        .debug-view-search-item { font-size: 14px;line-height: 1; }

        .debug-msg > .debug-view-result {
            > i { cursor: pointer; }
            strong, div { display: none; }
        }
        .debug-msg > .debug-view-result.show-debug {
            strong, div { display: block; }
        }
        .debug-toggle-children {
            font-size: 14px;
            text-align: center;
            margin-left: 14vw;
            cursor: pointer;
            display: none;
        }
        .debug-sub-content { display: none; }
    }
    .debug-msg > div { line-height: 1.2; }
}

       #debug-wrapper .debug-toggle-children {
            font-size: 14px;
            text-align: center;
            margin-left: 14vw;
            cursor: pointer;
            display: none;
        }
       .show-debug-items #debug-wrapper .debug-toggle-children {
            display: block;
        }
#debug-elements { display: none; }
.show-debug-items #debug-elements { display: block; }
.show-debug-items {
    #debug-wrapper #debug-elements { position: absolute; top: 100px; left: 0; width: 40vw; 
    }
    #debug-wrapper .debug-item, #debug-wrapper .debug-item div { display: block;  line-height: 1.4;
        .debug-toggle-children {display: block; font-size: 12px;} }
}
#debug-view-result > i { display: none; }

.debug-sub-content { border-left: 40px solid black; }
#debug-wrapper .debug-item { margin: 0; }
.show-debug-items #debug-wrapper .debug-item, .show-debug-items #debug-wrapper .debug-item div { border-radius: 0; }
.debug-toggle-children {
    padding: 12px 16px;
    background: #eee;
    border-radius: 7px;
    border: 1px solid #ddd;
}
#debug-wrapper .debug-toggle-children + .debug-sub-content { display: none; }
#debug-wrapper .debug-sub-content.show-debug { display: block; }